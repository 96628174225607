<template>
    <div>
        <!-- Title and Logo -->
        <h-retribusi />
        <!-- end Logo and Title -->
        <panel title="Tambah Modul" class="panel panel-success">
            <!-- Tools -->
            <div class="pl-4 py-2 border shadow-sm">
                <span class="px-2"
                    ><b-button variant="primary" to="/users/modul/list"
                        ><i class="fa fa-arrow-circle-left pr-2"></i>
                        Kembali</b-button
                    ></span
                >
            </div>
            <!-- end of Tools -->

            <!-- Form input -->
            <div class="py-3">
                <b-card title="Tambah User">
                    <b-card-body>
                        <form action="#">
                            <div class="form-group row m-b-15">
                                <label
                                    class="col-md-2 col-form-label font-weight-bold"
                                    >Nama Modul
                                    <span class="text-danger">*</span></label
                                >
                                <div class="col-md-7">
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Masukkan Nama Modul"
                                    />
                                </div>
                            </div>
                            <div class="form-group row m-b-15">
                                <label
                                    class="col-md-2 col-form-label font-weight-bold"
                                    >Aksi
                                    <span class="text-danger">*</span></label
                                >
                                <div class="col-md-4">
                                    <v-select
                                        :options="kodeRekeningOptions"
                                        placeholder="Pilih Aksi"
                                    ></v-select>
                                </div>
                            </div>
                            <div class="form-group row m-b-15">
                                <label
                                    class="col-md-2 col-form-label font-weight-bold"
                                    >Link Modul
                                    <span class="text-danger">*</span></label
                                >
                                <div class="col-md-7">
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Masukkan Link Modul"
                                    />
                                </div>
                            </div>
                            <div class="form-group row m-b-15">
                                <label
                                    class="col-md-2 col-form-label font-weight-bold"
                                    >Aktif
                                    <span class="text-danger">*</span></label
                                >
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div
                                                class="radio radio-css radio-inline"
                                            >
                                                <input
                                                    type="radio"
                                                    id="inlineCssRadio1"
                                                    value="1"
                                                />
                                                <label for="inlineCssRadio1"
                                                    >YA</label
                                                >
                                            </div>
                                            <div
                                                class="radio radio-css radio-inline"
                                            >
                                                <input
                                                    type="radio"
                                                    id="inlineCssRadio2"
                                                    value="0"
                                                />
                                                <label for="inlineCssRadio2"
                                                    >Tidak</label
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div class="float-right">
                                <span>
                                    <b-button
                                        variant="primary"
                                        type="submit"
                                        squared
                                        :disabled="isDisabled"
                                    >
                                        <span v-if="isDisabled"
                                            ><b-spinner
                                                variant="light"
                                                small
                                                label="loading"
                                            ></b-spinner
                                        ></span>
                                        <i class="fa fa-save" v-else></i>
                                        Simpan
                                    </b-button>
                                </span>
                                <span class="px-2">
                                    <b-button
                                        variant="secondary"
                                        squared
                                        class="px-4"
                                        @click="reset"
                                        ><i class="fa fa-redo-alt"></i>
                                        Reset</b-button
                                    >
                                </span>
                            </div>
                        </form>
                    </b-card-body>
                </b-card>
            </div>
            <!-- end of form -->
        </panel>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import HRetribusi from "../../../components/npwr/HRetribusi.vue";
import axios from "axios";

export default {
    components: { HRetribusi },
    data() {
        return {
            // Tools
            isBusy: false,
            sortBy: null,
            sortDesc: false,
            filter: null,
            filterBy: "all",
            pageOptions: [5, 10, 15, 25],
            perPage: 10,
            currentPage: 1,
            totalRows: 0,

            // fields
            fields: [
                {
                    key: "index",
                    label: "No.",
                },
                {
                    key: "npwr",
                    label: "KODE",
                    sortable: true,
                },
                {
                    key: "nama",
                    label: "URUSAN",
                    sortable: true,
                },
                {
                    key: "nik",
                    label: "KLASIFIKASI URUSAN PEMERINTAH",
                    sortable: true,
                },
                {
                    key: "alamat",
                    label: "NAMA ORGANISASI (SKPD)",
                },
                {
                    key: "actions",
                    label: "Opsi",
                },
            ],
        };
    },
    computed: {
        fieldOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => {
                    return {
                        text: f.label,
                        value: f.key,
                    };
                });
        },
    },
    methods: {
        // data table
        myGetData(ctx) {
            // ctx =
            // berasal dari tag <b-table></b-table>
            //this.$Progress.start();
            this.isBusy = true;
            let promise = axios.get("/api/manajemen/tarif", {
                params: {
                    page: ctx.currentPage,
                    perpage: ctx.perPage,
                    sortby: ctx.sortBy,
                    sortdesc: ctx.sortDesc,
                    filter: ctx.filter,
                    filterby: this.filterBy,
                },
            });
            return promise
                .then((response) => {
                    const items = response.data.data;
                    // Data Pagination
                    // configPagination(response.data.meta);
                    this.totalRows = response.data.meta.total;
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.finish();
                    this.isBusy = false;
                    return items;
                })
                .catch((error) => {
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.fail();
                    this.isBusy = false;
                    // Returning an empty array, allows table to correctly handle
                    // internal busy state in case of error
                    return [];
                });
        },
        reload() {
            // with id="masTable" in tag b-table
            // this.$root.$emit('bv::refresh::table', 'masTable')
            // with property ref="masTable" in tag b-table
            this.$refs.masTable.refresh();
        },
        changeFilterBy() {
            if (this.filter) {
                this.$refs.masTable.refresh();
            }
        },
        swalNotification(swalType, id) {
            var btnClass = swalType == "error" ? "danger" : swalType;
            btnClass = swalType == "question" ? "primary" : btnClass;
            this.$swal({
                title: "Anda yakin ?",
                text: "Anda tidak akan bisa mengembalikan data!",
                type: swalType,
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: "Hapus",
                cancelButtonText: "Cancel",
                confirmButtonClass: "btn m-r-5 btn-" + btnClass + "",
                cancelButtonClass: "btn btn-default",
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .delete("/api/manajemen/tarif/" + id)
                        .then((response) => {
                            this.$swal({
                                title: "Data Berhasil Dihapus!",
                                icon: "success",
                                type: "success",
                                showCloseButton: true,
                                showConfirmButton: false,
                                timer: 1800,
                            });
                            this.reload();
                        });
                }
            });
        },
        editButton(index) {
            this.$router.push({ name: "EditRefRek1", params: { id: index } });
        },
    },
};
</script>
